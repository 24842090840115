
import MBDatatablePlus from "@/components/mb-datatable/DataTablePlus.vue";
import { computed, onActivated, onMounted, onUpdated, ref } from "vue";
import {
  FilterOption,
  SortCounter,
  SortOption,
  TableHeader,
  TaggingItem,
} from "@/core/directive/interface/common";
import { useI18n } from "vue-i18n";
import {
  formatUtcDate,
  setModuleBCN,
  settlementLastDayDate,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { ApiRefundProcessing } from "@/core/api";
import _ from "lodash";
import { CommonDateType } from "@/core/directive/type/common";
import { CancelTokenSource } from "axios";
import {
  getRefundStatus,
  useComputedFn,
} from "@/core/directive/function/refundProcessing";
import { refundStatus } from "@/core/directive/type/refundProcessing";
import { MenuComponent } from "@/assets/ts/components";
import { RefundOrderListItem } from "@/core/directive/interface/refundProcessing";

export default {
  name: "refund-processing-list",
  components: {
    MBDatatablePlus,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const tableData = ref<Array<RefundOrderListItem>>([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(10);
    const sortOrder = ref("desc");
    const sortLabel = ref("");
    const tableLoading = ref(false);
    const search = ref<string>("");
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);
    const filterOptions = ref<Array<FilterOption>>([]);

    const tableHeader = computed(() => {
      let arr: TableHeader[] = [
        {
          name: t("refundProcessing.list.refundNo"),
          key: "refund_order_no",
        },
        {
          name: t("refundProcessing.list.orderNo"),
          key: "sales_order_no",
        },
        {
          name: t("refundProcessing.list.yabandOrderId"),
          key: "pay_order_no",
          sortable: false,
        },
        {
          name: t("refundProcessing.list.refundAmount"),
          key: "amount",
          sortable: false,
        },
        {
          name: t("refundProcessing.list.status"),
          key: "status",
        },
        {
          name: t("refundProcessing.list.refundDate"),
          key: "refund_time",
          align: "right",
        },
        {
          name: t("common.actions"),
          key: "actions",
          sortable: false,
          align: "right",
        },
      ];
      return arr;
    });

    const options = ref({
      defaultSort: [...sortOptions.value] as SortOption[],
      filter_group: [] as FilterOption[],
      pick_date_range: [] as CommonDateType[],
      source: (refundStatus.Created +
        "," +
        refundStatus.Approve +
        "," +
        refundStatus.Refunding +
        "," +
        refundStatus.Cancelled +
        "," +
        refundStatus.Failed +
        "," +
        refundStatus.Refunded +
        "," +
        refundStatus.Error) as any,
      cancelSource: null as CancelTokenSource | null,
      sourceLoading: false,
      sourceOptions: [
        {
          label: t("common.all"),
          value:
            refundStatus.Created +
            "," +
            refundStatus.Approve +
            "," +
            refundStatus.Refunding +
            "," +
            refundStatus.Cancelled +
            "," +
            refundStatus.Failed +
            "," +
            refundStatus.Refunded +
            "," +
            refundStatus.Error,
        },
        {
          label: t("refundProcessing.status.created"),
          value: refundStatus.Created,
        },
        {
          label: t("refundProcessing.status.approve"),
          value: refundStatus.Approve,
        },
        {
          label: t("refundProcessing.status.pending"),
          value: refundStatus.Refunding,
        },
        {
          label: t("refundProcessing.status.cancelled"),
          value: refundStatus.Cancelled,
        },
        {
          label: t("refundProcessing.status.failed"),
          value: refundStatus.Failed,
        },
        {
          label: t("refundProcessing.status.refunded"),
          value: refundStatus.Refunded,
        },
        {
          label: t("refundProcessing.status.error"),
          value: refundStatus.Error,
        },
      ] as TaggingItem[],
    });

    const getDateType = computed(() => {
      let format = "YYYY-MM-DD",
        valueFormat = "YYYY-MM-DD";
      return {
        format,
        valueFormat,
      };
    });

    const disabledDate = (time: Date) => {
      const startDate = new Date("2022-01-31");
      return (
        time.getTime() > Date.now() || time.getTime() < startDate.getTime()
      );
    };

    const defaultDate = () => {
      // options.value.pick_date_range = [
      //   new Date("2022-02-01T00:00:00.000Z").toISOString(),
      //   settlementLastDayDate({
      //     value: 0,
      //     isStart: false,
      //     format: getDateType.value.valueFormat,
      //   }),
      // ];
    };

    const setOtherFilter = computed(() => {
      let arr: FilterOption[] = [];
      if (
        options.value.pick_date_range &&
        options.value.pick_date_range.length > 0
      ) {
        arr.push({
          field: "refund_time",
          value: options.value.pick_date_range[0]!,
          condition: "gteq",
        });
        arr.push({
          field: "refund_time",
          value: options.value.pick_date_range[1]!,
          condition: "lteq",
        });
      }
      if (typeof options.value.source === "string") {
        arr.push({
          field: "status",
          value: options.value.source.split(","),
          condition: "in",
        });
      } else {
        arr.push({
          field: "status",
          value: options.value.source,
          condition: "eq",
        });
      }
      return arr;
    });

    const setCommonFilter = computed(() => {
      return {
        page: currentPage.value,
        page_size: pageSize.value,
        search_value: search.value,
        sort_orders: sortOptions.value,
        filter_group: [...filterOptions.value, ...setOtherFilter.value],
      };
    });

    /**
     * @description: 请求数据
     */
    const getList = async () => {
      tableLoading.value = true;

      const { data } = await ApiRefundProcessing.getRefundOrderList(
        setCommonFilter.value
      );
      tableLoading.value = false;
      if (data.code == 0) {
        tableData.value.splice(0, tableData.value.length, ...data.data.items);
        total.value = data.data.total;
      }
    };

    /**
     * @description: 渲染：状态
     */
    const getStatus = useComputedFn(getRefundStatus);

    /**
     * @description: 获取列表
     */
    const updateList = () => {
      currentPage.value = 1;
      getList();
    };

    /**
     * @description: 重置过滤器（只修改参数）
     */
    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    /**
     * @description: 重置过滤器（拉取数据）
     */
    const handleFilterReset = () => {
      resetFilter();
      updateList();
    };

    /**
     * @description: 重置排序（拉取数据）
     */
    const handleSortReset = () => {
      resetColumnSort();
      updateList();
    };

    /**
     * @description: 重置排序（只修改参数）
     */
    const resetColumnSort = () => {
      sortOptions.value.splice(
        0,
        sortOptions.value.length,
        ...options.value.defaultSort
      );
      sortOrder.value = "desc";
      sortLabel.value = "";
    };

    /**
     * @description: 切换页码时
     */
    const onCurrentPageChange = (page) => {
      currentPage.value = page;
      getList();
    };

    /**
     * @description: 切换记录条数时
     */
    const onRowsPerPageChange = (pagesize) => {
      pageSize.value = pagesize;
      updateList();
    };

    const sortCounterFlag = [] as SortCounter[];

    /**
     * @description: 排序独立计数
     */
    const sortCounter = (field: string) => {
      if (sortCounterFlag.some((item) => item.field === field)) {
        sortCounterFlag.forEach((item) => {
          if (item.field === field) {
            item.counter = item.counter + 1;
          }
        });
      } else {
        sortCounterFlag.splice(0, sortCounterFlag.length, {
          field: field,
          counter: 1,
        });
      }
    };

    /**
     * @description: 排序3次判断
     */
    const onSortCounterThree = (val) => {
      let resetFlag = false;
      sortCounterFlag.forEach((item) => {
        if (item.field === val.columnName) {
          if (item.counter === 3) {
            sortCounterFlag.splice(0, sortCounterFlag.length);
            resetFlag = true;
          }
        }
      });
      return resetFlag;
    };

    /**
     * @description: 切换日期选择器时
     */
    const handleFilter = (val) => {
      resetColumnSort(); // 重置排序
      updateList();
    };

    /**
     * @description: 切换状态筛选器时
     */
    const handleSourceSelect = (val) => {
      resetFilter();
      resetColumnSort();
      updateList();
    };

    /**
     * @description: 排序时
     */
    const onColumnSort = (val: any) => {
      sortCounter(val.columnName);
      const resetFlag = onSortCounterThree(val);
      if (resetFlag) {
        handleSortReset();
      } else {
        let thisDirection = "";
        sortCounterFlag.forEach((item) => {
          if (item.field === val.columnName) {
            if (item.counter === 1) {
              thisDirection = "asc"; //第一次正序
            }
            if (item.counter === 2) {
              thisDirection = "desc"; //第二次倒序
            }
          }
        });
        sortOrder.value = thisDirection;
        const newSortRule = [] as SortOption[];
        newSortRule.push({
          field: val.columnName,
          direction: thisDirection,
        });
        sortOptions.value.splice(0, sortOptions.value.length, ...newSortRule);
        sortLabel.value = val.columnName;
        updateList();
      }
    };

    /**
     * @description: 搜索时
     */
    const debounceSearch = _.debounce(updateList, 1000);
    const searchItems = () => {
      debounceSearch();
    };
    const removeSearch = () => {
      search.value = "";
      updateList();
    };

    const init = () => {
      setModuleBCN(t, route);
      updateList();
    };

    onMounted(() => {
      options.value.defaultSort.splice(
        0,
        options.value.defaultSort.length,
        ...sortOptions.value
      );
      defaultDate();
      options.value.filter_group = [...setCommonFilter.value.filter_group];
      init();
      MenuComponent.reinitialization();
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatUtcDate,
      tableData,
      getStatus,
      currentPage,
      total,
      pageSize,
      search,
      tableHeader,
      sortOrder,
      sortLabel,
      options,
      onCurrentPageChange,
      onRowsPerPageChange,
      searchItems,
      removeSearch,
      tableLoading,
      onColumnSort,
      getDateType,
      handleFilter,
      handleFilterReset,
      handleSortReset,
      resetFilter,
      resetColumnSort,
      setCommonFilter,
      setOtherFilter,
      updateList,
      disabledDate,
      handleSourceSelect,
    };
  },
};
